<template>
  <div v-if="loading" class="loading">
    <span class="loader"></span>
  </div>

  <div v-else class="home-view">
    <img :src="'https://eid.manialab.sa/images/' + link.header_image" alt="Header">

    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-10">
      <div class="w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0" style="background-color: #f1f1f120;">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold text-white md:text-2xl">
            الرجاء إدخال اسمك الكامل للحصول على بطاقة تهنئة
          </h1>
          <form class="space-y-4 md:space-y-6">
            <div>
              <input type="text" v-model="fullName"
                class="outline-none text-white sm:text-sm rounded-lg block w-full p-2.5" style="color: #000;"
                placeholder="أكتب هنا">
              <span v-if="fullNameError" class="text-red-500 text-xs">خانة الاسم مطلوبة</span>
            </div>
            <button style="background-color: #f1ba32;" @click.prevent="send"
              class="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center">ارسال</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert';

export default {
  data() {
    return {
      link: '',
      fullName: '',
      fullNameError: false,
      loading: true
    }
  },
  methods: {
    send() {
      if (this.fullName == '') {
        this.fullNameError = true;
      } else {
        localStorage.setItem('name', this.fullName);
        this.$router.push({ path: '/card', query: { i: this.$route.query.i, t: this.$route.query.t } });
      }
    }
  },
  mounted() {
    this.axios.post('https://eid.manialab.sa/api/open-link',
      {
        'link_id': this.$route.query.i
      }
    ).then(response => {
      if (!response.data.success) {
        return swal('هناك خطأ ما', '', 'error');
      } else {
        this.link = response.data.data;
        this.loading = false;
      }
    });
  }
}
</script>

<style scoped>
.loading {
  background-color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f1ba32;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.home-view {
  direction: rtl;
  background-color: #000;
  height: 100vh;
  text-align: center;
}

img {
  width: 100%;
}
</style>
