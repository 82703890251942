<template>
    <div v-if="loading" class="loading">
        <span class="loader"></span>
    </div>

    <div v-else class="card-view">
        <div class="buttons">
            <button v-for="(image, index) in images" :key="image.id" :class="activeIndex == index ? 'active' : ''"
                @click="changeIndex(index)">{{
                    image.type
                }}</button>
        </div>

        <div class="image-border">
            <div ref="imageContainer" :class="['image-container', activeIndex == 0 ? 'portrait' : '']">
                <img :src="currentImage">
                <span :class="[activeIndex == 0 ? 'portrait' : 'landscape']">{{ this.name }}</span>
            </div>
        </div>

        <button class="download" @click="download">
            <i class="fas fa-download"></i> Download
        </button>

        <!-- <div class="social-media">
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-linkedin-in"></i>
            <i class="fa-brands fa-x-twitter"></i>
            <i class="fa-brands fa-facebook-f"></i>
            <i class="fa-brands fa-whatsapp"></i>
        </div> -->
    </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
    data() {
        return {
            name: localStorage.getItem('name'),
            link: '',
            images: [],
            activeIndex: 0,
            currentImage: '',
            loading: true
        }
    },
    methods: {
        changeIndex(index) {
            this.activeIndex = index;
            this.currentImage = 'https://eid.manialab.sa/images/' + this.images[index].image;
        },
        download() {
            this.axios.post('https://eid.manialab.sa/api/download',
                {
                    'link_id': this.$route.query.i
                }
            ).then(response => {
                if (!response.data.success) {
                    return swal('هناك خطأ ما', '', 'error');
                } else {
                    const element = this.$refs.imageContainer;
                    html2canvas(element, {
                        useCORS: true
                    }).then(canvas => {
                        const link = document.createElement('a');
                        link.href = canvas.toDataURL('image/png');
                        link.download = 'معايدة.png';
                        link.click();
                    });
                }
            });
        }
    },
    mounted() {
        this.axios.post('https://eid.manialab.sa/api/get-images',
            {
                'link_id': this.$route.query.i
            }
        ).then(response => {
            if (!response.data.success) {
                return swal('هناك خطأ ما', '', 'error');
            } else {
                this.link = response.data.data;
                this.images = response.data.data.images;
                this.currentImage = 'https://eid.manialab.sa/images/' + this.images[this.activeIndex].image;
                this.loading = false;
            }
        });
    },
}
</script>

<style scoped>
.loading {
    background-color: #000;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #f1ba32;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.card-view {
    /* direction: rtl; */
    background-color: #000;
    min-height: 100vh;
    text-align: center;
    color: #FFF;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.buttons button {
    background-color: #f1ba32;
    margin: 0 20px;
    width: 150px;
    height: 40px;
    border: 1px solid #f1ba32;
    border-radius: 5px;
    outline: none;
    text-transform: uppercase;
    transition: all 0.3s ease;
}

.buttons button:hover {
    background-color: #000;
    color: #f1ba32;
}

.buttons button.active {
    background-color: #000;
    color: #f1ba32;
}

.image-border {
    position: relative;
    display: block;
    margin: auto;
    width: fit-content;
    border: 1px solid #f1ba32;
}

.image-container.portrait {
    width: 500px;
}

.image-container img {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-container span {
    position: absolute;
    color: #f1ba32;
    text-transform: capitalize;
    font-size: 2rem;
    width: max-content;
}

.image-container span.portrait {
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-container span.landscape {
    bottom: 22%;
    right: 14%;
}

@media (max-width: 1024px) {
    .image-container span.landscape {
        bottom: 21%;
        right: 14%;
        font-size: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .image-container span.landscape {
        font-size: 1.2rem;
    }
}

@media (max-width: 666px) {
    .image-container span.landscape {
        font-size: 1rem;
    }
}

@media (max-width: 425px) {
    .image-container span.landscape {
        font-size: 0.6rem;
    }

    .image-container.portrait {
        width: 300px;
    }

    .image-container span {
        font-size: 1.2rem;
    }
}

.download {
    width: 300px;
    height: 40px;
    border: 1px solid #f1ba32;
    background-color: #f1ba32;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
    margin: 50px 0;
}

.download:hover {
    background-color: #000;
    color: #f1ba32;
}

.social-media {
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media i {
    color: #f1ba32;
    background-color: #000;
    border: 1px solid #f1ba32;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    cursor: pointer;
}
</style>
