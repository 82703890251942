<template>
    <div class="pin-view" v-if="!rightpin">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-10">
            <div class="w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0" style="background-color: #f1f1f120;">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 class="text-xl font-bold text-white md:text-2xl">
                        الرجاء إدخال الرمز السري للدخول
                    </h1>
                    <form class="space-y-4 md:space-y-6">
                        <div>
                            <input type="text" v-model="enteredPin"
                                class="outline-none text-white sm:text-sm rounded-lg block w-full p-2.5"
                                style="color: #000;" placeholder="أكتب هنا">
                            <span v-if="pinEmpty" class="text-red-500 text-xs">خانة الرمز مطلوبة</span>
                            <span v-if="pinWrong" class="text-red-500 text-xs">الرمز خاطئ</span>
                        </div>
                        <button style="background-color: #f1ba32;" @click.prevent="enterPin"
                            class="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center">دخول</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="chart-view" v-else>
        <h1>Link Bar Chart</h1>
        <canvas id="barChart"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    data() {
        return {
            link: '',
            link_id: 3,
            open_count: 0,
            downloads: 0,
            loading: true,
            pin: 13624,
            enteredPin: '',
            rightpin: false,
            pinEmpty: false,
            pinWrong: false
        }
    },
    methods: {
        enterPin() {
            if (this.enteredPin == '') {
                this.pinEmpty = true;
            } else if (this.enteredPin != this.pin) {
                this.pinWrong = true;
            } else if (this.enteredPin == this.pin) {
                this.rightpin = true;

                this.axios.post('https://eid.manialab.sa/api/chart',
                    {
                        'link_id': this.link_id
                    }
                ).then(response => {
                    if (!response.data.success) {
                        return swal('هناك خطأ ما', '', 'error');
                    } else {
                        this.link = response.data.data;
                        this.open_count = response.data.data.open_count;
                        this.downloads = response.data.data.downloads;

                        var ctx = document.getElementById('barChart').getContext('2d');
                        new Chart(ctx, {
                            type: 'bar',
                            data: {
                                labels: ['Open Count', 'Download Count'],
                                datasets: [{
                                    label: 'Counts',
                                    data: [this.open_count, this.downloads],
                                    backgroundColor: 'rgba(21, 63, 106, 0.3)',
                                    borderColor: 'rgba(21, 63, 106, 1)',
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                scales: {
                                    y: {
                                        beginAtZero: true
                                    }
                                }
                            }
                        });
                    }
                });
            }
        }
    },
}
</script>

<style scoped>
.pin-view {
    background: #000;
    height: 100vh;
    text-align: center;
    direction: rtl;
}

.chart-view {
    padding: 100px;
    text-align: center;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
</style>